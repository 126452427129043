import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import config from "../config/main.config";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import BreadCrumbs from "../components/breadcrumbs/BreadCrumbs";
import {getTokenFromLocalStorage, jumpToTop, MAX_MOBILE_WIDTH, UseWindowSize} from "../helper/util";
import EditTextComponent from "../components/editText/EditTextComponent";
import EditHTMLComponent from "../components/editHTML/EditHTMLComponent";
import SupportCard from "../components/supportCard/SupportCard";
import {loadTextsByNamespace} from "../../server/helper/ssrHelper";
import userStore from "../components/userManagement/userStore";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import {Label} from "semantic-ui-react";


function getAllOffers() {
    return new Promise(resolve => (fetch(config.BASE_URL + "offer/all", {
            method: "GET",
            headers: {
                "Access-Control-Allow-Origin": "*",
            }
        }).then(response => {
            if (response.status >= 200 && response.status < 300) {
                response.json().then((json) => {
                    return resolve(json)
                });
            } else {
                //ERROR on FETCH
            }
        }).catch(
            () => {
                //ERROR
            })
    ))
}

function updateOffer(editOffer) {
    return new Promise(resolve => (fetch(config.BASE_URL + "offer/", {
            method: "PUT",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({editOffer})
        }).then(response => {
            if (response.status >= 200 && response.status < 300) {
                response.json().then((json) => {
                    return resolve(json)
                });
            } else {
                //ERROR on FETCH
            }
        }).catch(
            () => {
                //ERROR
            })
    ))

}

function deleteOffer(offerId) {
    return new Promise(resolve => (fetch(config.BASE_URL + "offer/" + offerId, {
            method: "DELETE",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            if (response.status >= 200 && response.status < 300) {
                response.json().then((json) => {
                    return resolve(json)
                });
            } else {
                //ERROR on FETCH
            }
        }).catch(
            () => {
                //ERROR
            })
    ))

}

function createOffer(newOffer) {
    return new Promise(resolve => (fetch(config.BASE_URL + "offer/", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({newOffer})
        }).then(response => {
            if (response.status >= 200 && response.status < 300) {
                response.json().then((json) => {
                    return resolve(json)
                });
            } else {
                //ERROR on FETCH
            }
        }).catch(
            () => {
                //ERROR
            })
    ))
}

async function sendPDF(file) {
    try {
        const fileReader = new FileReader();
        await fileReader.readAsDataURL(file);
        fileReader.onload = async () => {
            await fetch(config.BASE_URL + "offer/sendPDF", {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": "Bearer " + getTokenFromLocalStorage()
                },
                body: JSON.stringify({file: fileReader.result, name: file.name.replace(/\s/g, "_")})
            });
        }
        return file.name.replace(/\s/g, "_")


    } catch (e) {
        console.error(e)
    }

}

async function getAllPDF() {
    return (await fetch(config.BASE_URL + "offer/sendPDF", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getTokenFromLocalStorage()
        },
    })).json()

}

const ShowOffers = (i18n, isEdit, allOffers, setAllOffers, selectPDF, setSelectPDF, allPDFs) => {
    const [editOffer, setEditOffer] = useState({edit: false, offer: {title: "", link: "", external: false}});

    if (isEdit) {
        return (
            <>
                {allOffers.map((offer, index) => (
                    <Card key={'card ' + index} fluid className={'job-card'}>
                        <Button.Group>
                            <Button circular color={'orange'} icon={'edit'} onClick={_ => {
                                setEditOffer({
                                    edit: true,
                                    indexOfOffer: index,
                                    offer: {
                                        id: offer.id,
                                        title: offer.title,
                                        link: offer.link,
                                        external: offer.link.startsWith('https://')
                                    }
                                })
                            }}/>
                            <Button circular color={'red'} icon={'trash'} onClick={_ => {
                                deleteOffer(offer.id).then(allOffers => {
                                    setAllOffers(allOffers);
                                })
                            }}/>
                        </Button.Group>

                        <Card.Content>
                            <p>{offer.title}</p>
                        </Card.Content>
                        <Card.Content extra>
                            <a href={offer.link}
                               target={'_blank'} rel={'noreferrer'}>
                                <button className={'button-1'}
                                        style={{float: "right", margin: 'auto'}}>{i18n.t("common:common.more")}</button>
                            </a>
                        </Card.Content>
                    </Card>
                ))}
                <Modal open={editOffer.edit}>
                    <Modal.Header>
                        Angebot Bearbeiten
                    </Modal.Header>
                    <Modal.Content>
                        <Form>
                            <Form.Field>
                                <label>{i18n.t('edit:edit.description')}</label>
                                <input value={editOffer.offer.title} onChange={e => {
                                    setEditOffer({
                                        ...editOffer,
                                        offer: {
                                            ...editOffer.offer,
                                            title: e.target.value
                                        }
                                    })
                                }}/>
                            </Form.Field>
                            <Form.Field>
                                    <div className={"ui buttonGroup"}>
                                        <label className={"ui button"} htmlFor={"uploadPDF"}
                                               style={{margin: "auto 1rem"}}>PDF
                                            Hochladen</label>
                                        <input className={"hide_cookie_div"} accept="application/pdf" type={"file"}
                                               id={"uploadPDF"} onChange={async (e) => {
                                            if (e.target.files[0]) {
                                                const result = await sendPDF(e.target.files[0]);
                                                setEditOffer({
                                                    ...editOffer,
                                                    offer: {
                                                        ...editOffer.offer,
                                                        link: "/downloads/" + result
                                                    }
                                                })
                                            }
                                        }}/>
                                        <Button onClick={_ => setSelectPDF(!selectPDF)}>Auswählen</Button>
                                    </div>
                            </Form.Field>
                            {selectPDF && <Form.Field>
                                <ul style={{listStyle: 'none'}}>
                                    {allPDFs.map((file) => <li style={{padding: '4px'}}>
                                        <input value={file} id={file} type="radio" name="select PDF"
                                               onClick={_ => {
                                                   setEditOffer({
                                                       ...editOffer,
                                                       offer: {
                                                           ...editOffer.offer,
                                                           link: "/downloads/" + file
                                                       }
                                                   })
                                               }}/>
                                        <label htmlFor={file}>{file}</label>
                                    </li>)}
                                </ul>
                            </Form.Field>}
                            <Form.Field>
                                <label>Extern</label>
                                <Form.Checkbox checked={editOffer.offer.external} onChange={_ => {
                                    setEditOffer({
                                        ...editOffer,
                                        offer: {
                                            ...editOffer.offer,
                                            external: !editOffer.offer.external
                                        }
                                    })
                                }}/>

                            </Form.Field>
                            <Form.Field>
                                <label>{i18n.t('edit:edit.link')}</label>
                                <Form.Input type={'text'} labelPosition={'left'} onChange={e => {
                                    setEditOffer({
                                        ...editOffer,
                                        offer: {
                                            ...editOffer.offer,
                                            link: e.target.value
                                        }
                                    })
                                }}>
                                    {editOffer.offer.external && <Label>https://</Label>}
                                    <input
                                        value={editOffer.offer.external ? editOffer.offer.link.replace('https://', '') : editOffer.offer.link}/>
                                </Form.Input>
                            </Form.Field>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color={'green'} onClick={_ => {
                            updateOffer(editOffer.offer).then(updatedOffer => {
                                let updatedOffers = [...allOffers];
                                updatedOffers[editOffer.indexOfOffer] = {
                                    id: updatedOffer.id,
                                    title: updatedOffer.title,
                                    link: updatedOffer.link
                                }
                                setAllOffers(updatedOffers);
                                setEditOffer({...editOffer, edit: false})
                            })
                        }}>{i18n.t('common:common.save')}</Button>
                        <Button color={'red'} onClick={_ => {
                            setEditOffer({...editOffer, edit: false})
                        }}>{i18n.t('common:common.cancel')}</Button>
                    </Modal.Actions>
                </Modal>
            </>
        )
    } else {
        return (
            allOffers.map((offer, index) => (
                <Card key={'card ' + index} fluid className={'job-card'}>
                    <Card.Content style={{display: 'flex'}}>
                        <p>{offer.title}</p>
                    </Card.Content>
                    <Card.Content extra>
                        <a href={offer.link}
                           target={'_blank'} rel={'noreferrer'}>
                            <button className={'button-1'}
                                    style={{float: "right", margin: 'auto'}}>{i18n.t("common:common.more")}</button>
                        </a>
                    </Card.Content>
                </Card>
            ))
        )
    }
}


const CreateNewOffer = (i18n, createNew, setCreateNew, setAllOffers, selectPDF, setSelectPDF, allPDFs) => {
    const [newOffer, setNewOffer] = useState({title: '', link: '', external: false})
    return (
        <Modal open={createNew}>
            <Modal.Header>HEADER CREATE NEW</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field>
                        <label>{i18n.t('edit:edit.description')}</label>
                        <input value={newOffer.title} placeholder={i18n.t('edit:edit.description') + '...'}
                               onChange={e => {
                                   setNewOffer({...newOffer, title: e.target.value})
                               }}/>
                    </Form.Field>
                    <Form.Field>

                            <div className={"ui buttonGroup"}>
                                <label className={"ui button"} htmlFor={"uploadPDF"} style={{margin: "auto 1rem"}}>PDF
                                    Hochladen</label>
                                <input className={"hide_cookie_div"} accept="application/pdf" type={"file"}
                                       id={"uploadPDF"} onChange={async (e) => {
                                    if (e.target.files[0]) {
                                        const result = await sendPDF(e.target.files[0]);
                                        setNewOffer({
                                            ...newOffer,
                                            link: "/downloads/" + result
                                        })
                                    }
                                }}/>
                                <Button onClick={_ => setSelectPDF(!selectPDF)}>Auswählen</Button>
                            </div>
                    </Form.Field>
                    {selectPDF && <Form.Field>
                        <ul style={{listStyle: 'none'}}>
                            {allPDFs.map((file) => <li style={{padding: '4px'}}>
                                <input value={file} id={file} type="radio" name="select PDF"
                                       onClick={_ => {
                                           setNewOffer({
                                               ...newOffer,
                                               link: "/downloads/" + file
                                           })
                                       }}/>
                                <label htmlFor={file}>{file}</label>
                            </li>)}
                        </ul>
                    </Form.Field>}
                    <Form.Field>
                        <label style={{margin: "auto 1rem"}}>Extern</label>
                        <Form.Checkbox className={'m-tb'} checked={newOffer.external} onChange={_ => {
                            setNewOffer({
                                ...newOffer, external: !newOffer.external
                            })
                        }}/>
                    </Form.Field>
                    <Form.Field>
                        <label>{i18n.t('edit:edit.link')}</label>
                        <Form.Input type={'text'} labelPosition={'left'}>
                            {newOffer.external && <Label>https://</Label>}
                            <input value={newOffer.link} placeholder={i18n.t('edit:edit.link') + '...'} onChange={e => {
                                setNewOffer({...newOffer, link: e.target.value})
                            }}/>
                        </Form.Input>
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button color={'green'} onClick={_ => {
                    createOffer(newOffer).then(newOffers => {
                        setAllOffers(newOffers)
                        setCreateNew(false);
                    })
                }}>{i18n.t('common:common.save')}</Button>
                <Button color={'red'} onClick={_ => {
                    setCreateNew(false);
                }}>{i18n.t('common:common.cancel')}</Button>
            </Modal.Actions>
        </Modal>
    )
}


const JobsPage = (props) => {
    const {i18n} = props;
    let applicationWidth = UseWindowSize().width;

    const [allOffers, setAllOffers] = useState([]);
    const [createNew, setCreateNew] = useState(false);
    const [selectPDF, setSelectPDF] = useState(false);
    const [allPDFs, setAllPDFs] = useState([]);


    useEffect(() => {
        if (typeof window !== "undefined") {
            jumpToTop(window);
        }
        getAllOffers().then(offers => {
            setAllOffers(offers);
        })

    }, []);

    useEffect(()=>{
        getAllPDF().then((files) => {
            setAllPDFs(files);
        })
    },[])

    const namespace = 'jobs'


    return (
        <div className={'grid ui'} style={{padding: 0, background: '#fbfcf7'}}>
            <div style={{display: 'none'}}>
                <BreadCrumbs breadCrumbData={[{title: 'Jobs', url: '/jobs'}]}/>
            </div>
            <div
                className={'row top-image-cut'}
                style={{backgroundImage: "url(" + config.BASE_URL_IMAGES + "StiftungTopImage.jpg)"}}>
                <img src={'/images/bows/Bogen6_Beige.svg'} alt={'bogen'}
                     className={'bow-6-position'}/>
            </div>
            <div className={'row centered page-content-wrapper'}>
                <div className={'sixteen wide computer fourteen wide mobile column stiftung-card-container'}>
                    {applicationWidth >= MAX_MOBILE_WIDTH ?
                        <Card className={'card-position stiftung-card-position'}>
                            <Card.Header className={'card-header'} s>
                                <h1><EditTextComponent id={`${namespace}-card-header-1`} namespace={namespace}/></h1>
                            </Card.Header>
                            <Card.Content style={{borderTop: '0'}}>
                                <p>
                                    <EditTextComponent id={`${namespace}-text-1`} namespace={namespace}/>
                                </p>
                            </Card.Content>
                        </Card> :
                        [<h1><EditTextComponent id={`${namespace}-card-header-1`} namespace={namespace}/></h1>,
                            <p>
                                <EditTextComponent id={`${namespace}-text-1`} namespace={namespace}/>
                            </p>]
                    }
                </div>
            </div>
            <div className={'row centered page-content-wrapper'}>
                <div className={'sixteen wide computer fourteen wide mobile column'}>
                    <h1><EditTextComponent id={`${namespace}-header-1`} namespace={namespace}/></h1>
                    <p><EditHTMLComponent id={`${namespace}-subheader-text`} namespace={namespace}/></p>
                </div>
                <div className={'sixteen wide computer fourteen wide mobile column'} style={{marginTop: '2rem'}}>
                    {ShowOffers(i18n, userStore.isAdmin, allOffers, setAllOffers, selectPDF, setSelectPDF, allPDFs)}
                </div>
                {
                    userStore.isAdmin &&
                    <Button icon={'plus'} color={'blue'} circular onClick={_ => {
                        setCreateNew(true);
                    }}/>
                }
                {CreateNewOffer(i18n, createNew, setCreateNew, setAllOffers, selectPDF, setSelectPDF, allPDFs)}
            </div>
            <div className={'row centered'} style={{background: '#7C962E'}}>
                <img src={'/images/bows/Bogen4_beige.svg'} alt={'bogen'}
                     className={'bow-4-position'}/>
                <SupportCard/>
            </div>
        </div>
    )
}

JobsPage.fetchData = () => {
    return loadTextsByNamespace(config.BASE_URL, 'jobs').then((text) => {
        return text;
    })
}

export default withTranslation(['common', 'edit'])(JobsPage);